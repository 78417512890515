import React, { useState, useEffect } from "react";

import { isEmail } from "validator";
import UserService from "../services/user.service";

import DidayzIcone from "../assets/didayz/didayz_icone.png";
import Alert from '@mui/material/Alert';

import { isFromPhone } from "./globalVariable.component";

function ContactUS(props) {
  const [email, setEmail] = useState('');
  const [emailValidity, setEmailValidity] = useState('valid');
  const [msg, setMsg] = useState('');
  const [msgValidity, setMsgValidity] = useState('valid');
  const [errorMsg, setErrorMsg] = useState('');
  const [msgPublishWithSuccess, setMsgPublishWithSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeEmail = event => {
    setEmail(event.target.value);

    if (emailValidity === "required" && event.target.value) {
      setEmailValidity("valid");
    }
    else if(emailValidity === "notvalid" && isEmail(event.target.value)) {
      setEmailValidity("valid");
    }
    else if(emailValidity === "toLong" && event.target.value.length <= 255)
    {
      setEmailValidity("valid");
    }
  }

  const changeMsg = event => {
    setMsg(event.target.value);

    if (msgValidity === "required" && event.target.value) {
      setMsgValidity("valid");
    }
  }

  const validateEmail = event => {
    if (!event.target.value) {
      setEmailValidity("required");
    }
    else if(!isEmail(event.target.value)) {
      setEmailValidity("notvalid");
    }
    else if(event.target.value.length > 255)
    {
      setEmailValidity("toLong");
    }
    else
    {
      setEmailValidity("valid");
    }
  };

  const validateMsg = event => {
    if (!event.target.value) {
      setEmailValidity("required");
    }
    else
    {
      setEmailValidity("valid");
    }
  }

  function handleSend(e) {
    e.preventDefault();

    if(!email || !isEmail(email) || !msg)
    {
      setErrorMsg("Veuillez renseigner un email valide et un message");
      return;
    }

    UserService.sendMsg(email, msg).then(
      (res) => {
        if(res[0] === "Succès")
        {
          setErrorMsg("");
          setMsgPublishWithSuccess(true);
          setEmail("");
          setMsg("");
  
          setTimeout(() => {
              setMsgPublishWithSuccess(false);
          }, 3000);
        }
        else if(res[0] === "Erreur")
        {
          setErrorMsg(res[1]);
        }
      },
      error => {
        setErrorMsg(error);
      }
    );
  }

  return (
    <div className="col-md-12" style={{height: "100%", display: "grid", alignItems: "center"}}>
      <div className="card card-container" style={isFromPhone ?  { borderRadius: "30px", width: "90vw", minWidth: "fit-content", margin: "0", justifySelf: "center", alignSelf: "center" } : { borderRadius: "30px", width: "30vw", minWidth: "fit-content", margin: "0", justifySelf: "center", alignSelf: "center" }}>
        <img
          src={DidayzIcone}
          alt="profile-img"
          className="profile-img-card"
        />
        {errorMsg === "" ?
            <></> :
            <div className="alert alert-danger texteLog" role="alert">
            {errorMsg}
            </div>
        }
        {msgPublishWithSuccess ? 
            <Alert severity="success" style={{ fontFamily: 'quasimoda', textAlign: "left", alignItems: "center" }}>Merci ! Votre message a bien été envoyé, l'équipe Didayz va revenir vers vous !</Alert> :
            <></>
        }
        <p className="texteLog" style={{textAlign: "center"}}>Une question ?<br></br>Une idée d'amélioration pour Didayz ?<br></br>Avant d'envoyer vos questions, peut-être trouverez-vous vos réponses dans la <a href="/guide">FAQ</a> rédigée par l'équipe de Didayz<br></br>Nous vous répondrons dans les plus brefs délais</p>
        <form onSubmit={handleSend}>
          <div className="form-group">
            <label htmlFor="email" className="texteLog">Email *</label>
            <input
              type="text"
              className="form-control texteLog"
              name="email"
              value={email}
              placeholder="Email pour vous contacter"
              onChange={changeEmail}
              onBlur={validateEmail}
            />
            {emailValidity === "required" ?
              <div className="alert alert-danger texteLog" role="alert">
                Ce champ est obligatoire
              </div> :
              emailValidity === "notvalid" ?
              <div className="alert alert-danger texteLog" role="alert">
                Saisir un email valide
              </div> :
              emailValidity === "toLong" ?
              <div className="alert alert-danger texteLog" role="alert">
                L'email ne doit pas dépasser les 255 caractères
              </div> :
              <></>
            }
          </div>
          <div className="form-group">
            <label htmlFor="message" className="texteLog">Message</label>
            <textarea
                className="form-control texteLog"
                name="message"
                rows="10"
                value={msg}
                placeholder="Saisissez ici votre requête, nous vous répondrons dans les plus brefs délais"
                onChange={changeMsg}
                onBlur={validateMsg}
            />
            {msgValidity === "required" ?
              <div className="alert alert-danger texteLog" role="alert">
                Ce champ est obligatoire
              </div> :
              <></>
            }
          </div>
          <div className="form-group">
            <button
              className="buttonLog"
              type="submit"
            >
              <span>ENVOYER</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUS;