import React, { useEffect } from "react";

function LegalNotice(props) {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return(
    <div className="col-md-12" style={{height: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column", paddingLeft: "5vw", paddingRight: "5vw"}}>
        <h3 className="titleDefault" style={{ textAlign: "center", alignSelf: "center" }}>Mentions légales</h3>
        <span className="texteLog" style={{ textAlign: "center", alignSelf: "center", fontSize: "calc(14px + 0.2vw)" }}>En vigueur au 01/01/2023</span>
        <p className="texteLog" style={{ marginTop: "5vw" }}>
            Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l'"Utilisateur", du site www.didayz.com, ci-après le "Site", les présentes mentions légales.
            <br></br><br></br>La connexion et la navigation sur le Site par l'Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.
            <br></br><br></br>Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
        </p>
        <h3 className="titleDefault" style={{ textAlign: "center", alignSelf: "flex-start", fontWeight: "bold", fontSize: "calc(14px + 0.5vw)", marginTop: "2vw" }}>ARTICLE 1 - L'EDITEUR</h3>
        <p className="texteLog" style={{ marginTop: "2vw" }}>
            Le suivi éditorial et graphique du site est assuré par Nicolas HUCHON auto-entrepreneur, immatriculé au Registre du Commerce et des Sociétés de Nantes sous le numéro 921572764 dont le siège social est situé au 9 rue des Sept Maires Charette 44100 Nantes.<br></br><br></br>
            La conception technique du site et son ergonomie sont assurés par Adrien BOIGNE auto-entrepreneur, immatriculé au Registre du Commerce et des Sociétés d'Angers sous le numéro 892058645 dont le siège social est situé au 3 rue Amiral Nouvel de la Flèche 49240 Avrillé,<br></br>Numéro de téléphone 06.84.63.51.24,<br></br>Adresse e-mail : contact@didayz.com.<br></br>Le Directeur de la publication est Adrien BOIGNÉ<br></br>ci-après l'"Editeur".
        </p>
        <h3 className="titleDefault" style={{ textAlign: "center", alignSelf: "flex-start", fontWeight: "bold", fontSize: "calc(14px + 0.5vw)", marginTop: "2vw" }}>ARTICLE 2 - L'HEBERGEUR</h3>
        <p className="texteLog" style={{ marginTop: "2vw" }}>
            L'hébergeur du Site est la société OVH SAS, dont le siège social est situé au 2 rue Kellermann 59100 Roubaix. 
        </p>
        <h3 className="titleDefault" style={{ textAlign: "center", alignSelf: "flex-start", fontWeight: "bold", fontSize: "calc(14px + 0.5vw)", marginTop: "2vw" }}>ARTICLE 3 - ACCES AU SITE</h3>
        <p className="texteLog" style={{ marginTop: "2vw" }}>
            Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découler d'une nécessité de maintenance.
            <br></br><br></br>En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
        </p>
        <h3 className="titleDefault" style={{ textAlign: "center", alignSelf: "flex-start", fontWeight: "bold", fontSize: "calc(14px + 0.5vw)", marginTop: "2vw" }}>ARTICLE 4 - COLLECTE DES DONNEES</h3>
        <p className="texteLog" style={{ marginTop: "2vw" }}>
            Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
            <br></br><br></br>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.<br></br>L'Utilisateur exerce ce droit :
            <ul>
                <li>via un formulaire de contact</li>
                <li>via son espace personnel</li>
            </ul>
            <br></br>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l'Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
            <br></br><br></br>Pour plus d'informations, se reporter aux Conditions générales de l'application, accessible sur la page "Mon Compte" =={'>'} "Conditions générales"
            <br></br>Pour plus d'informations, se reporter à la partie Confidentialité de l'application, accessible sur la page "Mon Compte" =={'>'} "Confidentialité"
        </p>
    </div> 
  )
}

export default LegalNotice