import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

import { isFromPhone } from "./globalVariable.component";

import cardData from "../resources/articles.json";

function Author({ authors, date }) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center"
          }}
        >
          <AvatarGroup max={3}>
            {authors.map((author, index) => (
              <Avatar
                key={index}
                alt={author.name}
                src={author.avatar}
                sx={{ width: 24, height: 24 }}
              />
            ))}
          </AvatarGroup>
          <Typography style={{ fontFamily: 'quasimoda' }} variant="caption">
            {authors.map(author => author.name).join(", ")}
          </Typography>
        </Box>
        <Typography style={{ fontFamily: 'quasimoda' }} variant="caption">{date}</Typography>
      </Box>
    )
}


export default function Blog() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = index => {
    setFocusedCardIndex(index)
  }

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginLeft: 5, marginRight: 5, }}>
      <div>
        <label className="taglineHome" style={{fontSize: "calc(30px + 2vw)"}}>
          Nos articles 
        </label>
        <label className="descHome">Consultez tous nos articles sur les calendriers de l'avent mais aussi et surtout sur Didayz et ses possibilités infinies</label>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: isFromPhone ? "1fr" : "1fr 1fr" }}>
      {cardData.map((item) => (
        <Grid sx={{ margin: 1 }} key={item.idx}>
          <Link to={{ pathname: '/article/' + item.title }} className='disableLinkCss'>
            <Card
              variant="outlined"
              onFocus={() => handleFocus(0)}
              onBlur={handleBlur}
              tabIndex={item.idx}
              className={focusedCardIndex === 0 ? 'Mui-focused' : ''}
              sx={{
                ':hover': {
                  boxShadow: 5,
                  cursor: "pointer",
                },
              }}
            >
              <CardMedia
                component="img"
                alt={item.title}           
                src={item.img}
                sx={{
                  aspectRatio: '16 / 9',
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="caption" component="div">
                  {item.tag}
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ overflow: "ellipsis"}} gutterBottom dangerouslySetInnerHTML={{__html: item.description}} />
              </CardContent>
              <Author authors={item.authors} date={item.date} />
            </Card>
          </Link>
        </Grid>
      ))}
      </div>
    </Box>
  );
}
