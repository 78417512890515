import axios from 'axios';
import { URL_API } from '../components/globalVariable.component';

class UserService {
  async sendMsg(email, msg) {
    let responseCall = [];

    try {
      const response = await axios.post(URL_API + "/api/sendMsg", {
          email,
          msg
        });
      responseCall.push("Succès");
      responseCall.push(response.data);
      return responseCall;
    } catch (error) {
      responseCall.push("Erreur");
      responseCall.push(error.response.data.message);
      return responseCall;
    }
  }

  async newPassword(codeTmpTkn, newPassword) {
    let responseCall = [];
    try {
      const response = await axios.post(URL_API + "/api/auth/newPassword", {
        codeTmpTkn,
        newPassword
      });
      responseCall.push("Succès");
      responseCall.push(JSON.parse(JSON.stringify(response.data)));
      return responseCall;
    } catch (error) {
      responseCall.push("Erreur");
      responseCall.push(error.response.data.message);
      return responseCall;
    }
  }
}

export default new UserService();