import React, { useEffect } from "react";

function Prices(props) {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className="col-md-12" style={{height: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column", paddingLeft: "5vw", paddingRight: "5vw"}}>
        <h3 className="titleDefault" style={{ textAlign: "center", alignSelf: "center" }}>Nos prix</h3>
        <span className="texteLog" style={{ textAlign: "center", alignSelf: "center", fontSize: "calc(14px + 0.2vw)" }}>Cette politique de prix n'est pas encore rentrée en vigueur. Les Didayz sont, pour le moment, totalement gratuits</span>
        <p className="texteLog" style={{ marginTop: "5vw" }}>
            La tarification suivante ne s'applique que pour les personnes offrant un Didayz. Didayz est (et restera) toujours gratuit pour les personnes qui se font offrir un Didayz.
            <br></br><br></br>Les prix suivants sont appliqués lors de la diffusion du Didayz. Ils sont proportionnels au nombre de personnes à qui on souhaite partager le Didayz.
            <br></br><br></br>Cette politique de prix a été privilégiée car nous pensons qu'elle est juste. Les particuliers qui veulent utiliser Didayz pour offrir un agréable moment à un proche (la majeure partie des utilisateurs de Didayz) auront un plus faible montant à payer qu'une personne ou une organisation qui souhaite partager un Didayz à une large communauté.
            <br></br><br></br>Les prix appliqués sont les suivants :
            <br></br><br></br>
            <ul>
                <li>Prix = 80% du nombre de personnes à qui je souhaite partager mon Didayz.</li>
                <li>Les prix sont arrêtés à 40 € (50 personnes). Cela signifie que si je partage mon Didayz à 50 personnes, à 100 personnes ou à 1000 personnes, le prix restera le même : 40 €.</li>
                <li>Si le montant issu des 80% de mes utilisateurs n'est pas rond, le prix est arrondi à l'entier supérieur.</li>
            </ul>
            <br></br><br></br>Quelques exemples pour illustrer les prix appliqués :
        </p>
        <table style={{ borderCollapse: "collapse;"}}>
            <tr>
                <th>Nombre de personnes à qui le Didayz est partagé</th>
                <th>Prix</th>
            </tr>
            <tr>
                <td>1 utilisateur</td>
                <td>1 €</td>
            </tr>
            <tr>
                <td>2 utilisateurs</td>
                <td>2 €</td>
            </tr>
            <tr>
                <td>5 utilisateurs</td>
                <td>4 €</td>
            </tr>
            <tr>
                <td>10 utilisateurs</td>
                <td>8 €</td>
            </tr>
            <tr>
                <td>25 utilisateurs</td>
                <td>20 €</td>
            </tr>
            <tr>
                <td>50 utilisateurs</td>
                <td>40 €</td>
            </tr>
            <tr>
                <td>100 utilisateurs</td>
                <td>40 €</td>
            </tr>
            <tr>
                <td>500 utilisateurs</td>
                <td>40 €</td>
            </tr>
        </table>
    </div> 
  )
}

export default Prices