import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Typography from '@mui/material/Typography';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import articles from "../resources/articles.json";

export default function Article(props) {

    const params = useParams();
    let art;

    articles.forEach(function(article) {
        if(article.title === params.articleTitle) {
            art = article;
        }
    });

    if(art !== undefined) {
        return (
            <Box sx={{ display: "grid", marginLeft: 5, marginRight: 5, }}>
                <Button className="durationZone" variant="contained" sx={{ borderRadius: "20px", fontFamily: "quasimoda", fontSize: "10px", justifySelf: "center", marginBottom: "20px" }}>
                    Durée de lecture : {art.duration}
                </Button>
                <h1 style={{ fontFamily: "quasimoda", justifySelf: "center", textAlign: "center", fontSize: "calc(22px + 0.6vw)" }}>{art.title}</h1>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        alignItems: "center",
                        justifySelf: "center",
                        marginTop: "30px"
                    }}
                >
                    <AvatarGroup max={3}>
                        {art.authors.map((author, index) => (
                        <Avatar
                            key={index}
                            alt={author.name}
                            src={author.avatar}
                            sx={{ width: 32, height: 32 }}
                        />
                        ))}
                    </AvatarGroup>
                    <Box sx={{ display: "grid" }}>
                        <Typography style={{ fontFamily: 'quasimoda' }} variant="caption">
                            Ecrit par&nbsp;{art.authors.map(author => author.name).join(", ")}
                        </Typography>
                        <Typography style={{ fontFamily: 'quasimoda' }} variant="caption">
                            Le&nbsp;<strong>{art.date}</strong>
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ marginTop: 10, display: "grid", gap: 5 }}>
                    <Typography dangerouslySetInnerHTML={{__html: art.text1}} sx={{ fontFamily: "quasimoda", fontSize: 14 }}></Typography>
                    <img
                        srcSet={art.img}
                        src={art.img}
                        alt={art.title}
                        loading="lazy"
                        style={{ justifySelf: "center", maxHeight: 400, maxWidth: "100%" }}
                    />
                    <Typography dangerouslySetInnerHTML={{__html: art.text2}} sx={{ fontFamily: "quasimoda", fontSize: 14 }}></Typography>
                    <div style={{maxWidth: 800, width: "100%", justifySelf: "center", backgroundColor: "rgba(68, 55, 193, 0.2)", borderRadius: 5, padding: 10, marginLeft: 10, marginRight: 10}}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                alignItems: "center",
                                justifySelf: "center"
                            }}
                        >
                            <InfoOutlinedIcon></InfoOutlinedIcon>
                            <Typography dangerouslySetInnerHTML={{__html: art.nb}} variant="caption" sx={{ fontFamily: "quasimoda" }}></Typography>
                        </Box>
                    </div>
                    <Box sx={{ display: "grid", marginTop: 5 }}>
                        <Typography sx={{ fontFamily: "quasimoda", fontSize: 14 }}>Sources de l'article</Typography>
                        {art.src_links.map((link, index) => (
                            <a
                                key={"Source" + index}
                                href={link.src}
                                target="_blank"
                                rel="noreferrer"
                                style={{ fontFamily: "quasimoda", fontSize: 14 }}
                            >
                                {link.name}
                            </a>
                        ))}
                    </Box>
                </Box>
            </Box>
        );
    }
    else {
        return <h1>Erreur, article inconnu !</h1>
    }
}