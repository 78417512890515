import React, { useEffect } from "react";


function Guide(props) {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className="col-md-12" style={{height: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column", paddingLeft: "5vw", paddingRight: "5vw"}}>
        <h3 className="titleDefault" style={{ textAlign: "center", alignSelf: "center" }}>Foire aux Questions</h3>
        <h4 className="titleDefault" style={{ color: "black", fontSize: "calc(12px + 0.8vw)", marginTop: "30px" }}>Comment créer un Didayz ?</h4>
        <div className="texteLog">
          Pour créer un Didayz que vous souhaitez offrir à quelqu'un, utilisez le bouton (+) présent dans la bar de navigation de l'application, puis cliquez sur "Créer un nouveau Didayz".<br></br>
          Sur le formulaire de création du Didayz, vous devez renseigner un titre, un jour de fin, une durée (en jours) et une clé d'accès. L'image du Didayz et le message d'introduction sont facultatifs mais, pour proposer une expérience optimale à la personne à qui vous souhaitez offrir le Didayz, nous conseillons de les alimenter.<br></br>
          Vous pouvez ensuite cliquer sur le bouton "Créer", votre Didayz est créé et vous êtes redirigés vers sa page de description comprenant la liste des jours (dont vous devez renseigner le contenu) et la description de votre Didayz.
        </div>
        <h4 className="titleDefault" style={{ color: "black", fontSize: "calc(12px + 0.8vw)", marginTop: "30px" }}>Pourquoi faire un Didayz ? À quelle occasion ?</h4>
        <div className="texteLog">
          Il n'existe pas de limite pour créer des Didayz.<br></br>
          L'idée principale de Didayz est d'aider, d'accompagner et de faire plaisir à un(des) proche(s) avant un évènement.<br></br>
          Pour un anniversaire, un mariage, un examen à passer, un évènement sportif... nous avons listé quelques idées d'évènements pour vous guider (depuis l'application, sur la page "Mon Compte" =={'>'} "Consulter les inspirations proposées") mais nous savons que vous développerez le potentiel de Didayz au delà de ce que nous avons imaginé.
        </div>
        <h4 className="titleDefault" style={{ color: "black", fontSize: "calc(12px + 0.8vw)", marginTop: "30px" }}>Comment offrir un Didayz ?</h4>
        <div className="texteLog">
          Lorsque votre Didayz est paramétré (vous avez alimenté du contenu pour chaque jour), vous pouvez cliquer sur le bouton "Publier" (sur la carte de description de votre Didayz).<br></br>
          Un Didayz publié signifie qu'il est accessible par quiconque qui connait sa clé d'accès.<br></br>
          Vous pouvez donc soit partager la clé d'accès du Didayz à vos proches à qui vous souhaitez l'offrir, soit utiliser le bouton "Partager" qui vous permettra de l'envoyer directement.<br></br>
          Il est important de noter que la première information qui s'affichera lors de l'ajout de votre Didayz par la personne à qui vous décidez de l'offrir sera le message d'introduction. Il peut donc être utile d'expliquer le contexte du Didayz et sa raison.
        </div>
        <h4 className="titleDefault" style={{ color: "black", fontSize: "calc(12px + 0.8vw)", marginTop: "30px" }}>Je suis bloqué pour créer, consulter ou diffuser mon Didayz, que faire ?</h4>
        <div className="texteLog">
          Si vous ne trouvez pas de réponse dans notre FAQ, vous pouvez envoyer un message à l'équipe Didayz
          <ul>
            <li>Depuis l'application : sur la page "Mon Compte" =={'>'} "Contactez-nous"</li>
            <li>Depuis le site internet : dans la rubrique "Contact"</li>
          </ul>
          <br></br>Nous nous ferons un plaisir de vous accompagner pour résoudre votre problème le plus rapidement possible.
        </div>
        <h4 className="titleDefault" style={{ color: "black", fontSize: "calc(12px + 0.8vw)", marginTop: "30px" }}>Mon Didayz arrive à échéance, puis-je récupérer les contenus de chaque jour pour les garder en souvenir ?</h4>
        <div className="texteLog">
          Si c'est un Didayz que vous avez offert, un mail automatique vous sera envoyé un mois après le dernier jour de votre Didayz contenant l'archive de votre Didayz. Le contenu de chaque jour y sera exporté. Après cette date, votre Didayz sera supprimé de notre plateforme.<br></br>
          Si c'est un Didayz que l'on vous a offert, vous pouvez contacter la personne qui vous a offert le Didayz afin que celle-ci vous envoi les différents contenus.
        </div>
        <h4 className="titleDefault" style={{ color: "black", fontSize: "calc(12px + 0.8vw)", marginTop: "30px" }}>Le contenu des jours de mon Didayz est-il limité en terme de stockage ?</h4>
        <div className="texteLog">
          Le contenu global d'un Didayz est limité à 500 Mo et un Didayz ne peut pas dépasser les 30 jours.<br></br>Chaque jour est limité à 100 Mo maximum.
        </div>
        <h4 className="titleDefault" style={{ color: "black", fontSize: "calc(12px + 0.8vw)", marginTop: "30px" }}>Quel est le prix d'un Didayz ?</h4>
        <div className="texteLog">
          Un Didayz est, et restera, toujours gratuit pour les personnes qui se font offrir le Didayz.<br></br>
          Pour les personnes qui créent et offrent un Didayz, cette partie est aussi, pour le moment, totalement gratuite.
          <br></br>Si une tarification devait être appliquée à l'avenir, celle-ci s'appliquera de manière progressive, selon le nombre de personnes à qui l'on souhaite partager/offrir le Didayz.
          <br></br><br></br><br></br><br></br>
          Si ce guide ne répond pas à toutes vos questions ou que vous souhaitez nous faire part de vos idées, n'hésitez pas à nous contacter depuis notre <a href="/contactus">formulaire de contact</a> !
        </div>
    </div> 
  )
}

export default Guide