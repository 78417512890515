import React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import UserService from "../services/user.service";

import Modal from 'react-modal';

import DidayzIcone from "../assets/didayz/didayz_icone.png";
import { isFromPhone } from "./globalVariable.component";

const customStylesModal = {
  content: {
    top: '50%',
    left: '50%',
    width : isFromPhone ? '80%' : '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

function NewPassword() {
  const [password, setPassword] = useState('');
  const [passwordValidity, setPasswordValidity] = useState('valid');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordValidity, setConfirmPasswordValidity] = useState('valid');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [txtModal, setTxtModal] = useState("");
  const [errorMsg, setErrorMsg] = useState('');

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changePassword = event => {
    setPassword(event.target.value);

    if (passwordValidity === "required" && event.target.value) {
      setPasswordValidity("valid");
    }
    else if(passwordValidity === "notvalid" && /[A-Z]/.test(event.target.value) && /[a-z]/.test(event.target.value) && /[0-9]/.test(event.target.value) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(event.target.value) && event.target.value.length >= 8 && event.target.value.length <= 60)
    {
      setPasswordValidity("valid");
    }
  }

  const changeConfirmPassword = event => {
    setConfirmPassword(event.target.value);

    if(confirmPasswordValidity === "required" && event.target.value) {
      setConfirmPasswordValidity("valid");
    }
    else if(confirmPasswordValidity === "notvalid" && event.target.value === password)
    {
      setConfirmPasswordValidity("valid");
    }
  }

  /* Validations */
  const validatePassword = event => {
    if (!event.target.value) {
      setPasswordValidity("required");
    }
    else if(!/[A-Z]/.test(event.target.value) || !/[a-z]/.test(event.target.value) || !/[0-9]/.test(event.target.value) || !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(event.target.value) || event.target.value.length < 8 || event.target.value.length > 60)
    {
      setPasswordValidity("notvalid");
    }
    else
    {
      setPasswordValidity("valid");
    }
  }

  const validateConfirmPassword = event => {
    if (!event.target.value) {
      setConfirmPasswordValidity("required");
    }
    else if(event.target.value !== password)
    {
      setConfirmPasswordValidity("notvalid");
    }
    else
    {
      setConfirmPasswordValidity("valid");
    }
  }

  function handleResetPwd(e) {
    e.preventDefault();

    UserService.newPassword(params.idTmpToken, password).then(
        (res) => {
          if(res[0] === "Succès")
          {
            setErrorMsg("");
            setModalIsOpen(true);
            setTxtModal("Votre mot de passe a été mis à jour avec succès, vous pouvez maintenant vous connecter.");
          }
          else if(res[0] === "Erreur")
          {
            setErrorMsg(res[1]);
          }     
        },
        error => {
          console.log(error);
        }
      );
  }

  return (
    <div className="col-md-12" style={{height: "100%", display: "grid", alignItems: "center"}}>
      <div className="card card-container" style={{ borderRadius: "30px", width: "30vw", minWidth: "fit-content", margin: "0", justifySelf: "center", alignSelf: "center" }}>
        <img
          src={DidayzIcone}
          alt="profile-img"
          className="profile-img-card"
        />
        <form onSubmit={handleResetPwd}>
          {errorMsg === "" ?
            <></> :
            <div className="alert alert-danger texteLog" role="alert">
              {errorMsg}
            </div>
          }    
          <div className="form-group">
            <label htmlFor="password" className="texteLog">Nouveau mot de passe *</label>
            <input
              type="password"
              className="form-control texteLog"
              name="password"
              value={password}
              placeholder="Saisir votre nouveau mot de passe"
              onChange={changePassword}
              onBlur={validatePassword}
            />
            {passwordValidity === "required" ?
              <div className="alert alert-danger texteLog" role="alert">
                Ce champ est obligatoire
              </div> :
              passwordValidity === "notvalid" ?
              <div className="alert alert-danger texteLog" role="alert">
                Votre mot de passe ne respecte pas le format suivant : <ul><li>Au moins une majuscule</li><li>Au moins une minuscule</li><li>Au moins un chiffre</li><li>Au moins un caractère spécial</li><li>Au moins 8 caractères</li><li>Moins de 60 caractères</li></ul>
              </div> :
              <></>
            }
          </div>
          <div className="form-group">
            <label htmlFor="validepassword" className="texteLog">Confirmer le nouveau mot de passe *</label>
            <input
              type="password"
              className="form-control texteLog"
              name="validepassword"
              value={confirmPassword}
              placeholder="Confirmer votre nouveau mot de passe"
              onChange={changeConfirmPassword}
              onBlur={validateConfirmPassword}
            />
            {confirmPasswordValidity === "required" ?
              <div className="alert alert-danger texteLog" role="alert">
                Ce champ est obligatoire
              </div> :
              confirmPasswordValidity === "notvalid" ?
              <div className="alert alert-danger texteLog" role="alert">
                La confirmation du mot de passe est incorrecte
              </div> :
              <></>
            }
          </div>
          <div className="form-group">
            <button
              className="buttonLog"
              type="submit"
              disabled={password !== "" && confirmPassword !== "" && password === confirmPassword && passwordValidity === "valid" && confirmPasswordValidity === "valid" ? false : true}
            >
              <span>Réinitialiser mon mot de passe</span>
            </button>
          </div>
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {setModalIsOpen(false)}}
        style={customStylesModal}
      >
          <label className="texteLog" dangerouslySetInnerHTML={{ __html: txtModal }}></label>
          <button className="buttonLog" style={{maxWidth: "200px", margin: "auto"}} onClick={() => {navigate('/login')}}>
            <span>Se connecter</span>
          </button>
      </Modal>
    </div>
  );
}

export default NewPassword;